.loading-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	flex-direction: column;
}

.loading-container img {
	width: 50px;
	animation: rotation 10s infinite linear;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.dots-loader {
    width: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: dots-fade 1s infinite linear alternate;
}

@keyframes dots-fade {
    0%  {box-shadow: 12px 0 #000, -12px 0 #0002;background: #000 }
    33% {box-shadow: 12px 0 #000, -12px 0 #0002;background: #0002}
    66% {box-shadow: 12px 0 #0002,-12px 0 #000; background: #0002}
    100%{box-shadow: 12px 0 #0002,-12px 0 #000; background: #000 }
}
