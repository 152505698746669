.loading-article-children {
	animation: rotation infinite linear 2.00s;
	/* for a slightly more dynamic bounce: cubic-bezier(.36,-0.53,.64,1.61) */
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
