body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/*background: whitesmoke;*/
}

.modal-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 800px;
	min-width: 400px;
	border: 2px solid black;
	border-radius: 5px;
	background: white;
	padding: 10px;
}

.dragging-cnt {
	position: absolute;
	border: 1px solid black;
	border-radius: 5px;
	background: white;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
	padding: 10px;
	transform: translate(-10000px, -10000px);
}

.dragging-cnt > img {
	margin: 5px;
	width: 15px;
	height: 20px;
}

#panel-container {
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.gutter {
	background-color: #02b3c5;
}

.gutter.gutter-horizontal {
	cursor: col-resize;
}

.gutter.gutter-vertical {
	cursor: row-resize;
}

.wrap {
	display: flex;
}

.isOverNote {
	background-color: #eee!important;
	border-left-color: #eee!important;
	border-right-color: #eee!important;
	box-shadow: 0 1px 8px rgba(153, 153, 153, 0.7);
	transition-duration: 0ms!important;
}

.isOverTargetNote {
	box-shadow: 0 1px 8px rgba(153, 153, 153, 0.7);
}

.isOverMovingNote {
	box-shadow: 0 1px 8px rgba(153, 153, 153, 0.7);
}

